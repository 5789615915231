export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank details"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back"])},
        "dashboard-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back to dashboard"])},
        "overview": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Tracking <b>", _interpolate(_named("site")), "</b> for <b>", _interpolate(_named("keyword")), "</b>"])},
          "button-share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share this page"])},
          "button-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copied to clipboard!"])}
        },
        "stats": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking statistics"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["This keyword has not ranked since it started being tracked."])}
        },
        "competition": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking and competition"])},
          "open-google": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Show <a target=\"_blank\" href=\"", _interpolate(_named("link")), "\">in google</a>"])}
        },
        "initiatives": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initiatives"])}
        }
      },
      "ja": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキング詳細"])},
        "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
        "dashboard-link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ダッシュボードへ戻る"])},
        "overview": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキング"])},
          "subtitle": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["「<b>", _interpolate(_named("site")), "</b>」サイトを「<b>", _interpolate(_named("keyword")), "</b>」キーワードでランキングしています"])},
          "button-share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このページを共有する"])},
          "button-copied": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["コピーされました!"])}
        },
        "stats": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキングスタティスティックス"])},
          "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイトはこのキーワードでまだ検索に上の50件の中には表示されていません"])}
        },
        "competition": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["現在と競合状態のランキング"])},
          "open-google": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["<a target=\"_blank\" href=\"", _interpolate(_named("link")), "\">Google</a>に表示する"])}
        },
        "initiatives": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["取り組み"])}
        }
      }
    }
  })
}
