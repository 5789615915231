export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add more rankings"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can add more keywords or sites as well:"])},
        "keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
        "keyword-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your keyword"])},
        "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
        "button-saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saving.."])},
        "button-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add ranking"])},
        "result-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Congrats, new keyword was added!"])},
        "result-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["We're sorry, some error occurred. Please try again later."])},
        "keyword-tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For tips on what keywords your site might rank for, check out <a href=\"https://app.wordstream.com/fkt/app?cid=Web_Any_Products_Keywords_Grader_KWTool&ref=undefined\">wordstream's keyword tool</a>"])}
      },
      "ja": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキングを追加する"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["上記意外のキーワード・サイトを追跡追加できます:"])},
        "keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キーワード"])},
        "keyword-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのキーワード"])},
        "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイト"])},
        "button-saving": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["保存中.."])},
        "button-add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキングする"])},
        "result-added": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["おめでとう！ キーワードを追加できました!"])},
        "result-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ございません、エラーが発生しました。少し待ちして、試してください。"])},
        "keyword-tool": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["どのキーワードをランキングするのを<a href=\"https://app.wordstream.com/fkt/app?cid=Web_Any_Products_Keywords_Grader_KWTool&ref=undefined\">wordstreamのキーワードツール</a>をご覧ください"])}
      }
    }
  })
}
