export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Track your rank"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enter your email to get reports to here and then your keyword and your site:"])},
        "keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
        "keyword-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["your keyword"])},
        "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "or-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["login"])},
        "button-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start tracking!"])},
        "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try again"])},
        "registering": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registering.."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Give us a sec.."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error!"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Something went wrong. Please try again later."])}
        },
        "done": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["All done!"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You should very soon get a first email with your new rank. You can also head straight in to check it."])},
          "button-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to check your rank"])}
        }
      },
      "ja": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トラッキングを始まる"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキングを確認開始ために、以下にキーワード・サイトとどのメールアドレスにランキングアップデートを送って欲しいを入力してください："])},
        "keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キーワード"])},
        "keyword-placeholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["あなたのキーワード"])},
        "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイト"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
        "button-check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキングを確認する"])},
        "or-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["または"])},
        "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
        "try-again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["戻る"])},
        "registering": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["登録中.."])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ちょっと待ってね.."])}
        },
        "error": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["エラー!"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["申し訳ございません、エラーが発生しました。少し待ちして試してください。"])}
        },
        "done": {
          "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["できました!"])},
          "message": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["もう少しと最初のランキングメールが届けます。今でもすぐにログイン、チェックできます。"])},
          "button-login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["トラッキングを始まる"])}
        }
      }
    }
  })
}
