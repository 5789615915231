export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login to check your ranking history."])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
        "button-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Send login code"])},
        "message-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A code has been sent to your email. Please open the link provided inside to continue."])},
        "button-retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Retry"])}
      },
      "ja": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログイン"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキング履歴を見るために、ログインしてください。"])},
        "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["メールアドレス"])},
        "button-send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインコード送る"])},
        "message-sent": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ログインリンクは上記のメールに送りました。進むためにメールのリンク開いてください。"])},
        "button-retry": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["再試行"])}
      }
    }
  })
}
