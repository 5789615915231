<template>
  <div class="container">
    <section class="header">
      <h2 class="title">Pricing</h2>
      <p>
          SEO Ranking was built to be cheap. That's why your first thirty ranking checks are completely free! Then, you pay for what you use.
      </p>
    </section>

    <div class="page-section">
      <h3>How it works</h3>
      <p>
          Different teams have different needs. If you're working to improve ranking really quickly during after release, you want to have daily ranking updates. If you're working to improve your blog over time, once every week or so should be quite enough. We tailor our service to your needs.
      </p>
      <p>
        Our cost is based on the number of calls we make to Google to confirm your ranking. So that's what you pay for. We offer up the first thirty ranking checks for free, if you check your ranking for just one keyword just once a week, well, you're free to use our service for thirty free weeks! Perhaps that's all you need!
      </p>
      <p>
        If you like the service though and want to expand to more keywords, more sites or more frequent updates. We'll give you this for a pay-per-use basis. You pay for a refill of your rank checks and that's it.
      </p>
    </div>

    <div class="page-section">
      <h3>Pricing</h3>
      <table>
        <thead>
          <tr>
            <th>Rank checks</th>
            <th>Pricing</th>
            <th>Example</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>First 30</td>
            <td>Free</td>
            <td>Measure one keyword ranking every 3 days for 10 weeks</td>
          </tr>
          <tr>
            <td>+30 more</td>
            <td>$2</td>
            <td>Get another ten weeks, first year will cost 4x$2 = $8 total</td>
          </tr>
          <tr>
            <td>+100 more</td>
            <td>$5</td>
            <td>Check your top five keywords weekly for twenty weeks</td>
          </tr>
          <tr>
            <td>+600 more</td>
            <td>$10</td>
            <td>Big site, check ranking every 3 days for top 10 keywords to rank for each month, total $10/6 months</td>
          </tr>
        </tbody>
      </table>
    </div>

    <Footer />
  </div>
</template>
