export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rank history"])},
        "header": {
          "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Site"])},
          "keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keyword"])},
          "lastRanking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last ranking"])},
          "lastConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Last confirmed"])},
          "rankingStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ranking statistics"])}
        }
      },
      "ja": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキング履歴"])},
        "header": {
          "site": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["サイト"])},
          "keyword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["キーワード"])},
          "lastRanking": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新ランク"])},
          "lastConfirmed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["最新確認日時"])},
          "rankingStatistics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["詳細"])}
        }
      }
    }
  })
}
