<template>
  <div class="container">
    <section class="header">
      <h2 class="title">Oops! 404</h2>
      <p>Looks like this page you're looking for doesn't exist.</p>
    </section>
  </div>
</template>

<script>
</script>

<style scoped>
</style>