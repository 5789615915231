export default function (Component) {
  Component.__i18n = Component.__i18n || []
  Component.__i18n.push({
    "locale": "",
    "resource": {
      "en": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rankings"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Below is a list of shared rankings"])},
        "share-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share this page"])},
        "share-button-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["URL copied!"])}
      },
      "ja": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ランキング"])},
        "subtitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["以下は共有されたランキングの一覧です。"])},
        "share-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["このページを共有する"])},
        "share-button-done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ＵＲＬコピーした!"])}
      }
    }
  })
}
